<template>
    <v-container
        id="test"
        fluid
    >
        <v-row>
            <v-col
                v-if="!getEmailConfirmed"
                cols="12"
                md="8"
            >
                <email-alert />
            </v-col>
            <v-col
                v-if="allowPbP && getEmailConfirmed"
                cols="12"
                md="8"
            >
                <credit-card-list
                    v-if="allowPbP"
                />
            </v-col>
            <v-col
                cols="12"
                md="8"
            >
                <v-card v-if="user">
                    <v-card-title class="py-1">
                        Hej {{ user.customer.name }}
                    </v-card-title>
  
                    <!-- <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-select
                                    v-model="getSelectedPNumber"
                                    :items="pNumberList"
                                    disabled
                                    label="Vælg afdeling/P-nummer"
                                    :item-text="customerPNumberItemValue"
                                    item-value="pNumber"
                                    @change="pNumberChanged"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text> -->

                    <v-card-title class="py-1">
                        {{ $t('customerportal-invoiceInformation') }}
                    </v-card-title>
                    <v-card-text>
                        <v-form
                            v-model="valid"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field 
                                        v-model="eanNumber"
                                        :label="$t('customers/customerlist-customerDatabase-eanNumber')"
                                        :rules="!eanNumber ? [] : globalEan13Validator(eanNumber)"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field 
                                        v-model="email"
                                        label="Mail"
                                        :rules="globalEmailValidator(email)"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-right"
                                >
                                    <v-btn
                                        :disabled="!valid"
                                        @click="clickUpdateEanNumberAndEmail"
                                    >
                                        {{ $t('registrations-updateViewButton-updateButton') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-card-title class="py-1">
                        {{ $t('customerportal-addressInformation') }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field 
                                    :label="$t('admin/cvr-company')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.name"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field 
                                    :label="$t('attentiontype-1')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.cvrNumber"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field 
                                    v-if="locale === 'da'"
                                    :label="$t('customerportal-department')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.pNumber"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-address')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.address"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-number')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.addressNumber"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-letter')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.addressLetter"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-floor')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.addressFloor"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="3"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-sideDoor')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.addressSideOrDoor"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-zipcode')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.zipCode"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-city')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.city"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field 
                                    :label="$t('customers/customerlist-customerDatabase-createButtonPopup-municipal')"
                                    :readonly="true"
                                    :disabled="!getEmailConfirmed"
                                    :value="user.customer.municipalityName"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import emailAlert from '@/views/shared/EmailNotConfirmedAlert'
import CreditCardList from '../components/CreditCardList.vue'

export default {
    components: {
        emailAlert,
        CreditCardList
    }, 
    data() {
        return {
            working: false,
            user: null,

            pNumberList: [],
            selectedPNumber: null,

            //HACK HACK STUFF NOT THE PRETTIEST
            /*******************************************************
             * WE DONT UPDATE THE ENTIRE CUSTOMER SO WE HAVE AN UGLY
             * SOLUTION BECAUSE OF LACK OF TIME TO DO IT RIGHT
             *******************************************************/
            valid: false,
            eanNumber: null,
            email: null
        }
    },        
    computed: {   
        locale(){
            return process.env.VUE_APP_I18N_LOCALE
        },
        allowPbP(){
            return process.env.VUE_APP_ALLOW_PAY_BY_PLATE === 'true'
        },         
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'
        })
    },
    created(){        
        this.selectedPNumber = this.getSelectedPNumber

        this.LoadData()        
    },
    methods: {
        LoadData(){
            this.working = true
            Promise.all([
                this.FetchUser({
                    pNumber: this.getSelectedPNumber
                }),
            ]).then(results => {
                this.user = results[0]

                if(this.user && this.user.customer){
                    this.eanNumber = this.user.customer.eanNumber
                    this.email = this.user.customer.primaryEmail
                }

                this.FetchPNumberListByUser()
                    .then(result => {
                        this.pNumberList = result.pNumberList
                        this.working = false                        
                    })
            })
        },
        clickUpdateEanNumberAndEmail(){
            this.working = true
            
            this.FetchUpdateCustomerEanNumberAndEmail({
                pNumber: this.getSelectedPNumber,
                eanNumber: this.eanNumber,
                email: this.email
            })
                .then(() => {
                    this.working = false
                })
        },
        customerPNumberItemValue(pNumberItem){
            return pNumberItem.name + ' (' + pNumberItem.pNumber + ')'
        },
        pNumberChanged(pNumber){
            this.working = true
            this.setSelectedPNumber(pNumber)
            this.LoadData()
        },
        
        ...mapMutations({
            setSelectedPNumber: 'setSelectedPNumber'
        }),
        ...mapActions({            
            FetchUser: 'FetchUser',
            FetchPNumberListByUser: 'FetchPNumberListByUser',
            FetchUpdateCustomerEanNumberAndEmail: 'FetchUpdateCustomerEanNumberAndEmail'
        })        
    }
}
</script>